import React from "react";
import Geka from "../assets/images/GekuV1.png";
import Marquee from "./Marquee";
import MiniNavbar from "./MiniNavbar";

function Page1() {
  return (
    <>
      <MiniNavbar />
      <div
        className="flex items-center justify-center px-10 max-w-full"
        // style={{
        //   backgroundImage: `url(${require("../assets/images/OIG3.jpg")})`,
        // }}
      >
        <div className="flex flex-col lg:flex-row container mx-auto items-center w-full pt-2">
          <h1 className="md:text-8xl text-7xl pb-6 pt-4 font-molot text-[#1100ff] lg:hidden ">
            $GEKUu
          </h1>
          <div className="md:w-1/2 h-auto relative flex justify-center">
            <img src={Geka} alt="Aped Hero" className="w-[65%]" />
          </div>
          <div className="lg:w-1/2 w-full text-center lg:text-left lg:mt-0 mx-6 md:mx-0 space-y-4  ">
            <h1 className="text-7xl lg:text-9xl lg:text-[8rem] font-molot text-[#1100ff] hidden lg:flex">
              $GEKU
            </h1>
            <p className="text-center md:text-start text-sm md:text-lg md:hidden flex justify-center">
              CA: So11111111111111111111111111111111111111112
            </p>
            <div className="text-md md:text-xl space-y-3 text-justify font-londrina">
              <p>
                Geku stands as a remarkable fusion of Saiyan might and Gecko
                agility. Born with a pure heart like Goku, yet blessed with a
                Gecko's adaptive prowess, Geku possesses the unique ability to
                shed both skin and battle tactics at will. This extraordinary
                warrior can scale impossible heights and vanish at crucial
                moments, while maintaining the innocent spirit that sees good in
                all beings.
              </p>

              <p>
                Like a Gecko's regenerative tail, Geku's determination grows
                stronger after every setback. With Saiyan power flowing through
                his veins and Gecko's survival instincts guiding his path, Geku
                emerges as Earth's most versatile defender - a warrior whose
                loyalty to friends and family never wavers.
              </p>

              <p className="md:pt-10 py-8 text-center md:text-start text-sm md:text-lg hidden md:flex ">
                CA: So11111111111111111111111111111111111111112
              </p>
            </div>
          </div>
        </div>
      </div>
      <Marquee />
    </>
  );
}

export default Page1;

import React from "react";
import { FaTwitter, FaTelegramPlane } from "react-icons/fa";
import NavbarLogo from "../assets/images/logo3.png";

const Navbar = () => {
  return (
    <div className="font-molot ">
      {/* Main Navbar */}
      <header className="bg-transparent shadow-md">
        <div className="container mx-auto px-16 flex text-2xl justify-between items-center py-3">
          {/* Logo Navbar */}
          <div className="">
            <img
              src={NavbarLogo}
              className="bg-[#1100ff] rounded-full md:w-14 md:h-14 w-10 h-10"
              alt="Dragon Ball Z logo"
            />
          </div>

          {/* Social Icons */}
          <div className="flex space-x-4 text-[#1100ff]">
            <a
              className="hover:text-indigo-600"
              href=" "
              rel="noopener noreferrer"
            >
              <FaTwitter />
            </a>
            <a
              className="hover:text-indigo-600"
              href=" "
              rel="noopener noreferrer"
            >
              <FaTelegramPlane />
            </a>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Navbar;

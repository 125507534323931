import React from "react";

const MiniNavbar = () => {
  return (
    <div className="font-molot">
      <div className="bg-[#1100ff] text-xl py-1 px-4 flex items-center justify-between">
        <div className="text-white hidden md:flex md:w-6/12 justify-center">
          CHARACTERS
        </div>

        <div className="flex-1 flex md:justify-start justify-center">
          <div className="text-white flex space-x-20">
            <a
              className="hover:text-indigo-400"
              href=" "
              rel="noopener noreferrer"
            >
              CHART
            </a>
            <a
              className="hover:text-indigo-400"
              href=" "
              rel="noopener noreferrer"
            >
              BUY
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiniNavbar;

import React from "react";
import GekuFull from "../assets/images/IMG_0335.png";

const Page2 = () => {
  return (
    <div
      id="page2"
      className="lg:py-10 lg:px-16  px-2 py-4 flex flex-col lg:flex-row rounded-3xl justify-center items-center"
    >
      <div className="relative w-[90%] pb-[100%] md:w-[70%] md:pb-[55%]">
        <iframe
          src="https://www.jup.ag/swap/SOL-USDC"
          className="absolute top-0 left-0 bottom-0 w-full h-full border-0 rounded-2xl"
          title="Dex Screener"
          aria-label="Dex Screener"
        ></iframe>
      </div>

      {/* Gambar dengan posisi di sisi kanan */}
      <div className="w-full lg:w-1/2 h-auto">
        <div className="space-x-4">
          <img src={GekuFull} className="scale-10" alt="Geku Full" />
          <p>
            Geku stands as a remarkable fusion of Saiyan might and Gecko
            agility. Born with a pure heart like Goku, yet blessed with a
            Gecko's adaptive prowess, Geku possesses the unique ability to shed
            both skin and battle tactics at will. This extraordinary warrior can
            scale impossible heights and vanish at crucial moments, while
            maintaining the innocent spirit that sees good in all beings.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Page2;
